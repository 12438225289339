import { Link } from 'components/typography';
import { css, Theme } from '@emotion/react';
import { ReactElement } from 'react';
import { HeaderDesignEnum } from 'types/interface';

export const activeLinkStyles = (
  theme: Theme,
  isSelected: boolean,
  variant: HeaderDesignEnum = HeaderDesignEnum.Sleek
) => css`
  height: 100%;
  display: flex;
  align-items: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  font: normal 12px ${theme.fonts.header};
  text-align: center;
  text-transform: uppercase;

  ${variant === HeaderDesignEnum.Chunky &&
  css`
    padding: 10px ${theme.space[3]}px;
    font-weight: 700;
    color: ${theme.colors.mediumGrey};

    &:hover {
      transition: color 0.3s ease;
      color: white;
    }

    ${isSelected
      ? css`
          color: white;
        `
      : css`
          background: ${theme.colors.blacker};
        `}
  `}

  ${variant === HeaderDesignEnum.Sleek &&
  css`
    padding: 10px 0 8px;
    font-weight: 600;
    color: ${theme.colors.darkestGrey};
    border-bottom: 2px solid transparent;
    &:hover {
      transition: color 0.3 ease;
      color: ${theme.colors.headerPrimary};
    }

    ${isSelected &&
    css`
      font-weight: 700;
      color: ${theme.colors.headerPrimary};
      border-color: ${theme.colors.headerPrimary};
    `}
  `}
`;

const TopTab = ({
  children,
  href,
  onClick,
  isSelected = false,
  variant = HeaderDesignEnum.Sleek,
  ...restProps
}: {
  children: (ReactElement | string)[] | ReactElement | string;
  href?: string;
  onClick?: () => void;
  isSelected?: boolean;
  dynamicUrl?: string;
  variant?: HeaderDesignEnum;
}) => {
  const handleClick = (
    clickEvent: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    if (onClick) {
      clickEvent.preventDefault();
      onClick();
    }
  };

  return (
    <Link
      href={href}
      css={(theme: Theme) => activeLinkStyles(theme, isSelected, variant)}
      onClick={onClick ? handleClick : undefined}
      {...restProps}
    >
      {children}
    </Link>
  );
};

export default TopTab;
