import Tippy from '@tippyjs/react/headless';
import { ReactElement } from 'react';
import { Placement } from 'tippy.js';
import { Box } from 'components/box';
import { css } from '@emotion/react';

type TooltipProps = {
  children: ReactElement;
  content: string;
  placement?: Placement;
  backgroundColor?: string;
  color?: string;
};

const Tooltip = ({
  children,
  content,
  placement,
  backgroundColor,
  color,
}: TooltipProps) => (
  <Tippy
    placement={placement || 'left'}
    offset={[5, 8]}
    touch={['hold', 200]}
    render={attrs => (
      <Box
        p="2"
        lineHeight="1.4"
        borderRadius="3px"
        backgroundColor={backgroundColor || 'darkestGrey'}
        color={color || 'lightestGrey'}
        role="tooltip"
        {...attrs}
        css={css`
          &[data-placement^='top'] > .arrow {
            bottom: 4px;
          }

          &[data-placement^='bottom'] > .arrow {
            top: -4px;
          }

          &[data-placement^='left'] > .arrow {
            right: 4px;
          }

          &[data-placement^='right'] > .arrow {
            left: -4px;
          }
        `}
      >
        {content}
        <Box
          css={css`
            ::before {
              position: absolute;
              width: 8px;
              height: 8px;
              background: inherit;
              visibility: visible;
              content: '';
              transform: rotate(45deg);
            }
            visibility: hidden;
            background: inherit;
          `}
          className="arrow"
          data-popper-arrow
        />
      </Box>
    )}
  >
    <Box>{children}</Box>
  </Tippy>
);

export default Tooltip;
