import { Box, Flex } from 'components/box';
import { Text } from 'components/typography';
import { css, Theme } from '@emotion/react';
import { closeMenu, openDrawer } from '../../../../redux/global/actions';
import { useDispatch } from 'react-redux';
import { useIsLoggedIn, useCustomer } from 'hooks/customer';
import CartBubble from './cart';
import SearchButton from './search';
import { Link } from 'components/configurable-routing';
import WalletIcon from 'icons/wallet-simple.svg';
import { Menu as MenuIcon } from 'react-feather';
import DropdownMenu from 'components/_shared/widgets/pop-outs/dropdown-menu';
import { logout } from '../../../../redux/customer/actions';
import Loading from 'components/loading';
import config from '../../../../util/load-config';
import Tooltip from 'components/tooltip';
import { useContext } from 'react';
import { LayoutContext } from 'contexts/layout';
import { Customer } from 'types/types';
import { HeaderDesignEnum } from 'types/interface';

const accountMenuItemStyle = (
  theme: Theme,
  isButton = false,
  variant: HeaderDesignEnum = HeaderDesignEnum.Sleek
) => css`
  display: block;
  padding: ${theme.space[3]}px ${theme.space[3]}px;
  font-size: ${theme.fontSizes.r};
  font-family: ${theme.fonts.body};
  cursor: pointer;
  width: 100%;
  text-align: left;
  font-weight: ${theme.fontWeights.medium};

  ${isButton &&
  css`
    /* hyper specific subtraction for buttons unfortunately */
    padding: ${theme.space[3] - 2}px ${theme.space[3]}px;
  `}

  ${variant === HeaderDesignEnum.Sleek &&
  css`
    color: ${theme.colors.black};
    background-color: ${theme.colors.white};
    &:hover {
      background-color: ${theme.colors.blueGrey};
    }
  `}

  ${variant === HeaderDesignEnum.Chunky &&
  css`
    color: ${theme.colors.white};
    background-color: ${theme.colors.blacker};
    &:hover {
      background-color: ${theme.colors.black};
    }
  `}
`;

const Divider = ({
  variant = HeaderDesignEnum.Sleek,
}: {
  variant?: HeaderDesignEnum;
}) => (
  <hr
    css={theme => css`
      position: relative;
      outline: 0;
      border: 0;
      background-color: ${variant === HeaderDesignEnum.Sleek
        ? theme.colors.mediumGrey
        : theme.colors.black};
      height: 1px;
      margin-bottom: 0;
      margin-top: 0;
    `}
  />
);

const AccountMenu = ({
  isMenuLoading,
  setIsMenuLoading,
  isLoggedIn,
  customer,
  variant = HeaderDesignEnum.Sleek,
}: {
  isMenuLoading: boolean;
  setIsMenuLoading: () => void;
  isLoggedIn: boolean;
  customer?: Customer;
  variant?: HeaderDesignEnum;
}) => {
  const dispatch = useDispatch();
  const showCustomerMenu = isLoggedIn && !!customer?.firstName;
  const { setNewsletterModalIsOpen } = useContext(LayoutContext);

  return (
    <DropdownMenu
      value={showCustomerMenu ? customer.firstName : 'My Account'}
      flexProps={{ ml: 1 }}
      openedMenu="dropDownMenu"
      content={
        <Box
          role="link"
          mx={3}
          width="180px"
          bg={variant === HeaderDesignEnum.Sleek ? 'white' : 'blacker'}
          position="relative"
          css={theme => css`
            box-shadow: ${theme.shadows.cards};
            /* NOTE: tippyjs is pushing this box down by 1px, but that creates a gap, so need to move it back up */
            top: -1px;
          `}
        >
          <Loading
            isLoading={isMenuLoading}
            size="small"
            backgroundColor={
              variant === HeaderDesignEnum.Chunky ? 'transparent' : undefined
            }
          >
            {showCustomerMenu ? (
              <>
                <Link
                  href="/my-account"
                  css={theme => accountMenuItemStyle(theme, true, variant)}
                  onClick={setIsMenuLoading}
                >
                  My Account
                </Link>

                <Link
                  href="/my-orders"
                  css={theme => accountMenuItemStyle(theme, true, variant)}
                  onClick={setIsMenuLoading}
                >
                  My Orders
                </Link>

                <Link
                  href="/my-orders"
                  css={theme => accountMenuItemStyle(theme, true, variant)}
                  onClick={setIsMenuLoading}
                >
                  Log a Return
                </Link>
              </>
            ) : (
              <>
                <button
                  css={theme => accountMenuItemStyle(theme, true, variant)}
                  onClick={() => dispatch(openDrawer('login'))}
                >
                  Log In
                </button>

                <button
                  css={theme => accountMenuItemStyle(theme, true, variant)}
                  onClick={() => dispatch(openDrawer('createAccount'))}
                >
                  Create Account
                </button>
              </>
            )}

            <Divider variant={variant} />

            <button
              css={theme => accountMenuItemStyle(theme, true, variant)}
              onClick={() => {
                dispatch(closeMenu());
                setNewsletterModalIsOpen(true);
              }}
            >
              Subscribe
            </button>

            <Link
              target="_blank"
              href={config.helpCentreLinks.customerService}
              css={theme => accountMenuItemStyle(theme, true, variant)}
            >
              Help Centre
            </Link>

            {showCustomerMenu && (
              <>
                <Divider variant={variant} />

                <button
                  onClick={() => {
                    dispatch(logout());
                    dispatch(closeMenu());
                  }}
                  css={theme => accountMenuItemStyle(theme, true, variant)}
                >
                  Log out
                </button>
              </>
            )}

            {config.build.showVersionInMenu && config.build.meta.version && (
              <Text
                textAlign="right"
                color="darkGrey"
                p={2}
                fontSize="s"
                position="absolute"
                bottom="0"
                right="0"
              >
                v{config.build.meta.version}
              </Text>
            )}
          </Loading>
        </Box>
      }
    />
  );
};

const Actions = ({
  isMenuLoading,
  setIsMenuLoading,
  isSearchActive,
  toggleSearch,
  canSearch,
  variant = HeaderDesignEnum.Sleek,
  cartClassName,
}: {
  isMenuLoading: boolean;
  setIsMenuLoading: () => void;
  isSearchActive?: boolean;
  toggleSearch?: () => void;
  canSearch?: boolean;
  variant?: HeaderDesignEnum;
  cartClassName?: string;
}) => {
  const isLoggedIn = useIsLoggedIn();
  const customer = useCustomer();
  const dispatch = useDispatch();

  return (
    <>
      {!isSearchActive && canSearch && (
        <Tooltip content="Search" placement="bottom">
          <SearchButton toggleSearch={toggleSearch} />
        </Tooltip>
      )}

      {isLoggedIn && !!customer?.storeCredit?.balance?.value && (
        <Box className="hide-for-mobile-only">
          <Flex
            color="white"
            alignItems="center"
            backgroundColor="darkGrey"
            py="5px"
            px="10px"
            css={css`
              border-radius: 15px;
            `}
          >
            <WalletIcon
              viewBox="0 0 12 11"
              css={css`
                height: 12px;
                margin-bottom: 1px;
              `}
            />
            <Text fontFamily="header" fontSize="s" fontWeight={600} ml={1}>
              {customer.storeCredit.balance.formattedValue}
            </Text>
          </Flex>
        </Box>
      )}

      {variant === HeaderDesignEnum.Chunky && (
        <Box className="show-for-desktop" aria-label="My Account" tabIndex={0}>
          <AccountMenu
            isMenuLoading={isMenuLoading}
            setIsMenuLoading={setIsMenuLoading}
            isLoggedIn={isLoggedIn}
            customer={customer}
            variant={variant}
          />
        </Box>
      )}

      <Box className={cartClassName}>
        <Tooltip content="My Cart" placement="bottom">
          <CartBubble variant={variant} />
        </Tooltip>
      </Box>

      <Box
        className={
          variant === HeaderDesignEnum.Sleek
            ? 'show-for-mobile-only'
            : 'hide-for-desktop'
        }
      >
        <button
          title="menu"
          onClick={() => dispatch(openDrawer('mobileNavigation'))}
        >
          <MenuIcon
            css={theme => css`
              color: ${theme.colors.background};
            `}
          />
        </button>
      </Box>

      {variant === HeaderDesignEnum.Sleek && (
        <Box className="hide-for-mobile-only">
          <AccountMenu
            isMenuLoading={isMenuLoading}
            setIsMenuLoading={setIsMenuLoading}
            isLoggedIn={isLoggedIn}
            customer={customer}
            variant={variant}
          />
        </Box>
      )}
    </>
  );
};

export default Actions;
