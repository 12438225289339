import { useEffect, useState, memo, useRef } from 'react';
import { Box } from 'components/box';
import { Heading } from 'components/typography';
import { css, keyframes } from '@emotion/react';
import { HeaderDesignEnum } from 'types/interface';

const blinkAnimation = keyframes`
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
`;

const SAST_OFFSET = -120 * 60000;

const countDownTimer = (countDownDate: number, resetCallback?: () => void) => {
  // accesses the system time
  const now = new Date().getTime();

  // get South African Standard Time offset in minutes
  const localOffset = new Date().getTimezoneOffset() * 60000;

  const netOffset = SAST_OFFSET - localOffset;

  // adjusted end of day timestamp based on difference between SAST and local time
  const amendedCountDownDate = countDownDate + netOffset;

  // find the distance between now and the offset count down date
  const distance = amendedCountDownDate - now;

  if (distance <= 0) {
    // Timer reached "00:00:00", trigger the reset callback
    resetCallback && resetCallback();
    return '00:00:00';
  }

  // time calculations for hours, minutes and seconds
  const hours = `${Math.floor(
    (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  )}`.padStart(2, '0');
  const mins = `${Math.floor(
    (distance % (1000 * 60 * 60)) / (1000 * 60)
  )}`.padStart(2, '0');
  const seconds = `${Math.floor((distance % (1000 * 60)) / 1000)}`.padStart(
    2,
    '0'
  );

  return `${hours}:${mins}:${seconds}`;
};

const ClockBody = memo(() => {
  const [displayValue, setDisplayValue] = useState('');
  const [isBlinking, setIsBlinking] = useState(false);
  const [isIntervalRunning, setIsIntervalRunning] = useState(true);

  // end of day reference point
  const countDownDate = useRef(new Date().setHours(23, 59, 59));

  useEffect(() => {
    // don't run our interval we're not supposed to
    if (!isIntervalRunning) return;

    const intervalId = setInterval(() => {
      setDisplayValue(
        countDownTimer(countDownDate.current, () => {
          setIsBlinking(true);
          setIsIntervalRunning(false);
        })
      );
    }, 1000);

    return () => clearInterval(intervalId);
  }, [isIntervalRunning]);

  useEffect(() => {
    // don't wait for blinking when it ain't happening
    if (!isBlinking) return;

    const timeoutId = setTimeout(() => {
      setIsBlinking(false);
      setIsIntervalRunning(true);
    }, 10000);

    return () => clearTimeout(timeoutId);
  }, [isBlinking]);

  return (
    <Box
      letterSpacing={2}
      role="timer"
      aria-atomic="true"
      css={css`
        font-variant-numeric: tabular-nums;
        animation: ${isBlinking ? blinkAnimation : 'none'} 1500ms ease-in-out
          infinite;
      `}
    >
      {displayValue}
    </Box>
  );
});

const Clock = ({
  variant = HeaderDesignEnum.Sleek,
}: {
  variant?: HeaderDesignEnum;
}) => (
  <Box>
    <Heading
      textAlign="center"
      fontSize={[1, null, 2]}
      color="white"
      fontWeight="400"
    >
      DEALS EXPIRE IN:
    </Heading>
    <Heading
      fontSize={variant === HeaderDesignEnum.Sleek ? [2, 3, 4] : [3, 4, 6]}
      textAlign="center"
      color="white"
      css={theme => css`
        @media ${theme.mediaQueries.tinyScreenOnly} {
          font-size: ${theme.fontSizes[1]};
        }
      `}
    >
      <ClockBody />
    </Heading>
  </Box>
);

export default Clock;
