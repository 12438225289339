import { css } from '@emotion/react';
import { ShoppingCart as ShoppingCartIcon } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { HeaderDesignEnum } from 'types/interface';
import { RootState } from 'typesafe-actions';
import { openDrawer } from '../../../../redux/global/actions';
import Bubble from './bubble';

const CartBubble = ({
  variant = HeaderDesignEnum.Sleek,
}: {
  variant?: HeaderDesignEnum;
}) => {
  const dispatch = useDispatch();
  const quantity = useSelector((state: RootState) => state.cart.cart.quantity);

  return (
    <Bubble
      action={() => dispatch(openDrawer('cart'))}
      actionLabel="Toggle cart"
      bgColor="headerSecondary"
      content={!!quantity && quantity > 0 ? `${quantity}` : undefined}
      variant={variant}
    >
      <ShoppingCartIcon
        height="100%"
        width="100%"
        css={css`
          stroke: white;
          fill: white;
        `}
      />
    </Bubble>
  );
};

export default CartBubble;
