import { PermanentShopType } from 'types/types';

export const getPermanentShops = (): PermanentShopType[] => [
  {
    id: 'everyday-essentials',
    name: 'Everyday Essentials',
    shortName: 'Essentials',
    color: '',
    blurb: [],
  },
];
