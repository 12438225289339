import Tippy from '@tippyjs/react';
import { Flex, FlexProps } from 'components/box';
import { css } from '@emotion/react';
import { ChevronDown as IconDownward } from 'react-feather';
import { ReactElement } from 'react';
import { Text } from 'components/typography';
import { openMenu } from '../../../../../redux/global/actions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'typesafe-actions';

const DropdownMenu = ({
  value,
  content,
  color = 'white',
  flexProps,
  openedMenu,
}: {
  value: string;
  content: ReactElement;
  openedMenu: string;
  color?: string;
  flexProps?: FlexProps;
}) => {
  const activeMenu = !!useSelector((state: RootState) => state.global.openMenu);
  const dispatch = useDispatch();

  return (
    <Tippy
      arrow={false}
      trigger="click"
      allowHTML
      hideOnClick
      interactive
      placement="bottom"
      offset={[0, 9]}
      animation={false}
      maxWidth="100%"
      content={activeMenu ? content : ''}
      onMount={() => dispatch(openMenu(openedMenu))}
      /*
        We're using dispatch(openMenu('dropDownMenu')) because we're dispatching CloseMenu action on the header.
        Some time ago we refactored the menu to use Tippy and the part where we set the menu was left out by mistake.
        As a result, FE-640 was created to fix the issue of dropdown menu not closing
       */
    >
      <Flex
        {...flexProps}
        css={css`
          cursor: pointer;
        `}
      >
        <Text
          mr={2}
          color={color}
          lineHeight="16px"
          fontSize="12px"
          fontWeight="700"
          fontFamily="header"
          css={css`
            text-transform: uppercase;
          `}
        >
          {value}
        </Text>
        <IconDownward
          size={16}
          css={theme => css`
            stroke: ${theme.colors[color]};
          `}
        />
      </Flex>
    </Tippy>
  );
};

export default DropdownMenu;
