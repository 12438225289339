import SearchButtonSVG from 'shared/assets/icons/search.svg';
import { css } from '@emotion/react';

const SearchButton = ({ toggleSearch }: { toggleSearch?: () => void }) => (
  <button
    onClick={toggleSearch}
    css={theme => css`
      padding: 2px;

      &:active,
      &:focus {
        outline: 0 !important;
        box-shadow: none !important;
      }

      line-height: 10px;
      align-content: center;

      @media ${theme.mediaQueries.tabletUp} {
        padding: 5px;
      }
    `}
  >
    <SearchButtonSVG
      css={css`
        width: 16px;
        height: 16px;
        stroke-width: 3px;
      `}
    />
  </button>
);

export default SearchButton;
