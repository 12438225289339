import { css } from '@emotion/react';
import { Flex, Box } from 'components/box';
import { Text } from 'components/typography';
import { ReactElement } from 'react';
import { clickable } from 'shared/style';
import { HeaderDesignEnum } from 'types/interface';

const Bubble = ({
  action,
  actionLabel,
  children,
  bgColor,
  color = 'white',
  content,
  variant = HeaderDesignEnum.Sleek,
}: {
  action: () => void;
  actionLabel: string;
  children: ReactElement;
  bgColor: string;
  color?: string;
  content?: string;
  variant?: HeaderDesignEnum;
}) => (
  <button
    aria-label={actionLabel}
    onClick={action}
    css={theme => css`
      ${clickable}

      padding: 2px;

      ${variant === HeaderDesignEnum.Sleek &&
      css`
        @media ${theme.mediaQueries.tabletUp} {
          padding: 5px;
        }
      `}

      &:active,
      &:focus {
        outline: 0 !important;
        box-shadow: none !important;
      }

      ${variant === HeaderDesignEnum.Chunky &&
      css`
        border-radius: 15px;

        @media ${theme.mediaQueries.desktopUp} {
          padding: 5px 10px;
          background-color: ${theme.colors.cartBubble};

          ${!!content &&
          css`
            background-color: ${theme.colors.primary};
          `};
        }
      `}
    `}
  >
    <Flex alignItems="center" justifyContent="center" position="relative">
      <Box
        height={
          variant === HeaderDesignEnum.Sleek
            ? ['14px', null, '16px']
            : ['20px', '17px', '13px']
        }
        width={
          variant === HeaderDesignEnum.Sleek
            ? ['14px', null, '16px']
            : ['20px', '17px', '13px']
        }
        mr="2px"
      >
        {children}
      </Box>

      {!!content && variant === HeaderDesignEnum.Sleek && (
        <Box
          bg={bgColor}
          p="3px"
          borderRadius="100%"
          minWidth="16px"
          ml={0}
          position="absolute"
          top="-5px"
          right="-12px"
        >
          <Text
            fontFamily="header"
            fontSize={['xs', 's']}
            fontWeight={800}
            color={color}
            css={css`
              line-height: 10px;
            `}
          >
            {content}
          </Text>
        </Box>
      )}

      {!!content && variant === HeaderDesignEnum.Chunky && (
        <Box
          bg={['darkLime', null, 'transparent']}
          p={['3px', null, 0]}
          borderRadius={['50%', null, 0]}
          minWidth={['16px', null, 0]}
          ml={[0, '5px', '3px']}
          position={['absolute', 'static']}
          top={['-5px', 0]}
          right={['-7px', 0]}
        >
          <Text
            fontFamily="header"
            fontSize={['xs', 's']}
            fontWeight={800}
            color="white"
            css={theme => css`
              @media ${theme.mediaQueries.tabletDown} {
                text-shadow: 0px 0px 5px #00601d;
              }
            `}
          >
            {content}
          </Text>
        </Box>
      )}
    </Flex>
  </button>
);

export default Bubble;
